
import Vue from 'vue';
export default Vue.extend({
  props: {
    // selectedElement: {
    //   type:
    // },
    popoverProps: {
      default() {
        return {
          nudgeWidth: 400,
          closeOnContentClick: false,
          offsetOverflow: true,
          offsetX: true,
          width: 400,
          maxWidth: 420
        };
      }
    }
  },
  data: () => ({
    menu: false,
    left: false
  }),
  computed: {
    // data-date="2020-09-23"
    boundaryElement(): Element | null {
      return this.$parent.$el;
    }
  },
  methods: {
    close() {
      this.menu = false;
    },
    async getDomElementForPopover() {
      return await this.$parent.$el.querySelector(
        `.data-recurrence-id-${new Date(
          this.$route.params.recurrenceId
        ).valueOf()}`
      );
    }
  },
  async mounted() {
    this.$nextTick(async () => {
      (this as any).$refs.activator = await this.getDomElementForPopover();
      (this as any).$refs.isActive = true;
      // this.$refs.callActivate();
    });
    console.log(await this.getDomElementForPopover());

    setTimeout(async () => {
      console.log(new Date(this.$route.params.recurrenceId).valueOf());

      console.log(await this.getDomElementForPopover());
    }, 3000);
  },
  beforeRouteUpdate(to, from, next) {
    // const isNew = to.name === 'NewPopoverView'
    // this.$refs.activator = this.getDomElementForPopover;
    // this.$refs.activate();

    next();
  }
});
